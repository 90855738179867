// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#carousel {
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    transform-style: preserve-3d;
    perspective: 1000px;
    --items: 1; /* Total number of items */
    --middle: 1; /* Middle item index */
    --position: 0; /* Dynamic position based on state */
}

.item {
    position: absolute;
    width: 300px;
    height: 400px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    z-index: 1;
    transform: translateX(100%);
}

.item.active {
    opacity: 1;
}

.item:nth-of-type(1) {
    background-color: #f8d8d8;
}

.item:nth-of-type(2) {
    background-color: #c7c8dd;
}

.item:nth-of-type(3) {
    background-color: #c7e3ff;
}

.item:nth-of-type(4) {
    background-color: #f8d8d8;
}

.item:nth-of-type(5) {
    background-color: #c7c8dd;
}
`, "",{"version":3,"sources":["webpack://./src/Content/Reviews.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,4BAA4B;IAC5B,mBAAmB;IACnB,UAAU,EAAE,0BAA0B;IACtC,WAAW,EAAE,sBAAsB;IACnC,aAAa,EAAE,oCAAoC;AACvD;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,gEAAgE;IAChE,UAAU;IACV,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#carousel {\n    width: 100%;\n    height: 600px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    overflow: hidden;\n    position: relative;\n    transform-style: preserve-3d;\n    perspective: 1000px;\n    --items: 1; /* Total number of items */\n    --middle: 1; /* Middle item index */\n    --position: 0; /* Dynamic position based on state */\n}\n\n.item {\n    position: absolute;\n    width: 300px;\n    height: 400px;\n    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;\n    opacity: 0;\n    z-index: 1;\n    transform: translateX(100%);\n}\n\n.item.active {\n    opacity: 1;\n}\n\n.item:nth-of-type(1) {\n    background-color: #f8d8d8;\n}\n\n.item:nth-of-type(2) {\n    background-color: #c7c8dd;\n}\n\n.item:nth-of-type(3) {\n    background-color: #c7e3ff;\n}\n\n.item:nth-of-type(4) {\n    background-color: #f8d8d8;\n}\n\n.item:nth-of-type(5) {\n    background-color: #c7c8dd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
