// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    width:36rem; /* Rounded borders */
    
  }
  
  .card-content {
    display: flex;
    align-items: center;
  }
  
  .image-container {
    width: 30%;
    margin-right: 20px;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .module-info {
    width:70%;
  }
  
  .topic-time-container {

    display: flex;
    margin-top: -0.7em;
  }

  .learningStyles{
    font-size: 14px;
    color:#626161;
    scroll-padding: 12px;
  }
  
  .topic-value,
  .time-value {
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #DA3d33;
    padding: 5px 20px;
    margin-right:1rem;
    border-radius: 25px;

  }

  .title{
    margin-top: -0.2em;
    margin-bottom: 1em;
  }

  .divider{
    border:2px solid #DA3d33;
    border-radius: 5px;
    margin-top: -0.7em;
  }`, "",{"version":3,"sources":["webpack://./src/components/PMCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW,EAAE,oBAAoB;;EAEnC;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,SAAS;EACX;;EAEA;;IAEE,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,aAAa;IACb,oBAAoB;EACtB;;EAEA;;IAEE,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;;EAErB;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,wBAAwB;IACxB,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":[".card {\n    padding: 10px;\n    margin-bottom: 10px;\n    border-radius: 10px;\n    width:36rem; /* Rounded borders */\n    \n  }\n  \n  .card-content {\n    display: flex;\n    align-items: center;\n  }\n  \n  .image-container {\n    width: 30%;\n    margin-right: 20px;\n  }\n  \n  .image-container img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  \n  .module-info {\n    width:70%;\n  }\n  \n  .topic-time-container {\n\n    display: flex;\n    margin-top: -0.7em;\n  }\n\n  .learningStyles{\n    font-size: 14px;\n    color:#626161;\n    scroll-padding: 12px;\n  }\n  \n  .topic-value,\n  .time-value {\n    font-size: 12px;\n    font-weight: 600;\n    color: white;\n    background-color: #DA3d33;\n    padding: 5px 20px;\n    margin-right:1rem;\n    border-radius: 25px;\n\n  }\n\n  .title{\n    margin-top: -0.2em;\n    margin-bottom: 1em;\n  }\n\n  .divider{\n    border:2px solid #DA3d33;\n    border-radius: 5px;\n    margin-top: -0.7em;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
