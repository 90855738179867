// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    font-family: 'Manrope';
}
.App{
    width: 100vw !important;
}

.MuiAppBar-root {
    width: 100%;
    position: relative; /* Use relative instead of fixed */
    margin-right: 0 !important;
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,kBAAkB,EAAE,kCAAkC;IACtD,0BAA0B;EAC5B","sourcesContent":["*{\n    font-family: 'Manrope';\n}\n.App{\n    width: 100vw !important;\n}\n\n.MuiAppBar-root {\n    width: 100%;\n    position: relative; /* Use relative instead of fixed */\n    margin-right: 0 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
