// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container styling for Program Modules */
.program-modules {
    display: grid;
    grid-template-columns: 1fr; /* Default for small screens */
    gap: 16px; /* Adjust spacing between items */
    place-items: center;
    /* background-image: url('program_modules.png'); Replace with the correct path */
    background-size: cover; /* Ensures the image covers the entire area */
    background-position: center; /* Centers the image */
    width: 100%;
    padding: 20px; /* Optional for extra spacing */
  }
  
  /* Responsive grid layout for larger screens */
  @media (min-width: 600px) {
    .program-modules {
      grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens and above */
    }
  }
  
  @media (min-width: 960px) {
    .program-modules {
      grid-template-columns: repeat(3, 1fr); /* Three columns for large screens */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ProgramModules.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;IACI,aAAa;IACb,0BAA0B,EAAE,8BAA8B;IAC1D,SAAS,EAAE,iCAAiC;IAC5C,mBAAmB;IACnB,gFAAgF;IAChF,sBAAsB,EAAE,6CAA6C;IACrE,2BAA2B,EAAE,sBAAsB;IACnD,WAAW;IACX,aAAa,EAAE,+BAA+B;EAChD;;EAEA,8CAA8C;EAC9C;IACE;MACE,qCAAqC,EAAE,6CAA6C;IACtF;EACF;;EAEA;IACE;MACE,qCAAqC,EAAE,oCAAoC;IAC7E;EACF","sourcesContent":["/* Container styling for Program Modules */\n.program-modules {\n    display: grid;\n    grid-template-columns: 1fr; /* Default for small screens */\n    gap: 16px; /* Adjust spacing between items */\n    place-items: center;\n    /* background-image: url('program_modules.png'); Replace with the correct path */\n    background-size: cover; /* Ensures the image covers the entire area */\n    background-position: center; /* Centers the image */\n    width: 100%;\n    padding: 20px; /* Optional for extra spacing */\n  }\n  \n  /* Responsive grid layout for larger screens */\n  @media (min-width: 600px) {\n    .program-modules {\n      grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens and above */\n    }\n  }\n  \n  @media (min-width: 960px) {\n    .program-modules {\n      grid-template-columns: repeat(3, 1fr); /* Three columns for large screens */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
