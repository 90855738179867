// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styling for the program journey component */
.program-journey {
    padding: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .program-journey {
      padding: 10px; /* Adjust padding for smaller screens */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ProgramJourney.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,aAAa;EACf;;EAEA,sBAAsB;EACtB;IACE;MACE,aAAa,EAAE,uCAAuC;IACxD;EACF","sourcesContent":["/* Styling for the program journey component */\n.program-journey {\n    padding: 20px;\n  }\n  \n  /* Responsive Design */\n  @media (max-width: 768px) {\n    .program-journey {\n      padding: 10px; /* Adjust padding for smaller screens */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
