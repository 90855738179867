// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-component {
    font-family: Manrope;
    margin-top: 5rem;
    display: grid;
    place-items: center;
    
  }
  
  .tabs {
    display: flex;
    padding: 20px;
  }
  
  .tab {
    padding: 15px 40px;
    cursor: pointer;
    margin-right: 30px;
    background-color: #ddd9f5;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .tab.active {
    background-color: #fcdd97; /* Highlight color for active tab */
    font-weight: 900;
  }
  
  .content-container {
    margin-top: 20px;
  }
  
  .content h2 {
    color: #333;
  }
  
  .content p, .content ul {
    font-size: 16px;
    line-height: 1.5;
  }
  `, "",{"version":3,"sources":["webpack://./src/Content/Tabs.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;;EAErB;;EAEA;IACE,aAAa;IACb,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB,EAAE,mCAAmC;IAC9D,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".tabs-component {\n    font-family: Manrope;\n    margin-top: 5rem;\n    display: grid;\n    place-items: center;\n    \n  }\n  \n  .tabs {\n    display: flex;\n    padding: 20px;\n  }\n  \n  .tab {\n    padding: 15px 40px;\n    cursor: pointer;\n    margin-right: 30px;\n    background-color: #ddd9f5;\n    border-radius: 5px;\n    font-weight: 500;\n  }\n  \n  .tab.active {\n    background-color: #fcdd97; /* Highlight color for active tab */\n    font-weight: 900;\n  }\n  \n  .content-container {\n    margin-top: 20px;\n  }\n  \n  .content h2 {\n    color: #333;\n  }\n  \n  .content p, .content ul {\n    font-size: 16px;\n    line-height: 1.5;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
