// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-breadcrumbs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-left: 20px;
  }
  
  .breadcrumb-item {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 10px;
  }

  .breadcrumb-content{
    margin-left: 12px;
    
  }
  
  .breadcrumb-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fcdd97; /* Customize the color as needed */
    position: relative;
    z-index: 1;
  }
  
  .breadcrumb-title {
    margin-left: 2rem;
    font-size: 20px;
    padding: 2px;;
    color: #333;
    width:80%;
  }

  .breadcrumb-text {
    margin-left: 2.7rem;
    font-size: 16px;
    font-weight:600;
    margin-bottom: 1em;
    padding:2px;
    color: #333;
  }
  
  .breadcrumb-line {
    position: absolute;
    left: 30px; /* Center line with the circle */
    top: 30px;
    bottom:80px;
    width: 3px;
    background-color: #fcdd97; /* Customize line color as needed */
    z-index: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/VerticalBreadcrumbs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;EACtB;;EAEA;IACE,iBAAiB;;EAEnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB,EAAE,kCAAkC;IAC7D,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,SAAS;EACX;;EAEA;IACE,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,UAAU,EAAE,gCAAgC;IAC5C,SAAS;IACT,WAAW;IACX,UAAU;IACV,yBAAyB,EAAE,mCAAmC;IAC9D,UAAU;EACZ","sourcesContent":[".vertical-breadcrumbs {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    position: relative;\n    padding-left: 20px;\n  }\n  \n  .breadcrumb-item {\n    display: flex;\n    align-items: center;\n    position: relative;\n    padding-bottom: 10px;\n  }\n\n  .breadcrumb-content{\n    margin-left: 12px;\n    \n  }\n  \n  .breadcrumb-circle {\n    width: 25px;\n    height: 25px;\n    border-radius: 50%;\n    background-color: #fcdd97; /* Customize the color as needed */\n    position: relative;\n    z-index: 1;\n  }\n  \n  .breadcrumb-title {\n    margin-left: 2rem;\n    font-size: 20px;\n    padding: 2px;;\n    color: #333;\n    width:80%;\n  }\n\n  .breadcrumb-text {\n    margin-left: 2.7rem;\n    font-size: 16px;\n    font-weight:600;\n    margin-bottom: 1em;\n    padding:2px;\n    color: #333;\n  }\n  \n  .breadcrumb-line {\n    position: absolute;\n    left: 30px; /* Center line with the circle */\n    top: 30px;\n    bottom:80px;\n    width: 3px;\n    background-color: #fcdd97; /* Customize line color as needed */\n    z-index: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
